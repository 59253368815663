import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Generator", link: "/generator/" },
  {
    name: "Number Generator",
    link: "/number-generator/",
  },
]
const seeAlsoArray = [
  "/font-generator",
  "/password-generator",
  "/flip-a-coin",
  "/quote-generator",
]

function NumberGenerator(props) {
  let [outputResult, setoutputResult] = useState("")
  let [manyInput, setmanyInput] = useState(1)
  let [minInput, setminInput] = useState(1)
  let [maxInput, setmaxInput] = useState(99)

  function manyInputC(e) {
    setmanyInput(e.target.value)
  }
  function minInputC(e) {
    setminInput(e.target.value)
  }
  function maxInputC(e) {
    setmaxInput(e.target.value)
  }
  function numbers(many, min, highest) {
    var numbers = []
    for (var i = 0; i < many; i++) {
      var add = true
      var randomNumber = Math.floor(Math.random() * (highest - min + 1)) + min
      for (var y = 0; y < highest; y++) {
        if (numbers[y] === randomNumber) {
          add = false
        }
      }
      if (add) {
        numbers.push(randomNumber)
      } else {
        i--
      }
    }
    var highestNumber = 0
    for (var m = 0; m < numbers.length; m++) {
      for (var n = m + 1; n < numbers.length; n++) {
        if (numbers[n] < numbers[m]) {
          highestNumber = numbers[m]
          numbers[m] = numbers[n]
          numbers[n] = highestNumber
        }
      }
    }
    return numbers.join(" - ")
  }

  function generate() {
    var max = Number(maxInput)
    var min = Number(minInput)
    var many = Number(manyInput)
    if (many > max - min + 1) {
      setoutputResult("Error Length > Maximum Number 😵 ")
    } else if (max > 99999999) {
      setoutputResult("😵")
    } else {
      setoutputResult(numbers(many, min, max))
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Number Generator - truly random number online"
        description="Number Generator, truly random number Generator online, Simple input how many numbers you need and from minimum number to maximum number and click generate numbers, used for holding drawings, lotteries, and sweepstakes, to drive online games, for scientific applications, for art, music, and many ..."
        keywords={[
          "Number generator, random number, generate many number, generate number, random numbers generator, generate number lotteries, truly random number, make random numbers, many random number, random number online, generate number online, generate number from to,generate list of numbers, make list of numbers from to.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title>Number Generator</Title>
        <SubTitle>Random Number Generator</SubTitle>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>How Many Numbers</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  placeholder="..."
                  value={manyInput}
                  onChange={manyInputC}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Min Number</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  placeholder="Min..."
                  value={minInput}
                  onChange={minInputC}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Max Number</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  placeholder="Max..."
                  value={maxInput}
                  onChange={maxInputC}
                  max="9999999"
                />
              </Control>
            </Field>

            <Button
              type="button"
              value="Generate Numbers"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={generate}
            >
              Generate Numbers
            </Button>

            {outputResult !== "" ? (
              <>
                <p className="h2">
                  <strong> Result:</strong>
                </p>
                <p
                  id="output"
                  style={{ fontSize: "2em", transition: "all 0.5s ease" }}
                >
                  {outputResult}
                </p>
              </>
            ) : (
              ""
            )}
          </Column>
          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <SmallImg
                filename="randmnumber.png"
                alt="random Number generator"
              />
            </FlexDiv>
          </Column>
        </Columns>
        <br />
        <h3>Random Number Generator</h3>
        <p>
          Make a truly random number Generator online, one number, or many
          numbers simple input how many numbers you need and from the minimum
          number to the maximum number and click generate numbers.
          <br />
          Use this tool for holding drawings, lotteries and sweepstakes, to
          drive online games, for scientific applications, for art, music, and
          many ...
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />{" "}
      </section>
    </Layout>
  )
}

export default NumberGenerator
